import React, {useState} from 'react';
import ErrorMessage from './ErrorMessage';
import "../css/Search.css";

const Search = (props) => {
  const [cityZip, setCityZip] = useState('');
  const [mileRadius, setMileRadius] = useState(5);
  const [clinicType, setClinicType] = useState(props.clinicType);

  const get_title = () => {
    if (clinicType === 'pharmacy') {
      return clinicType.charAt(0).toUpperCase() + clinicType.slice(1) + " Search";
    }
    return 'Clinic Search';
  }

  let err = props.errorMessage === null
    ? null
    : <ErrorMessage message={props.errorMessage} />

  return (
    <div className="row">
      <div className="col-sm-12">
        <h2 className="portal-color">{get_title()}</h2>
        {err}
        <div className="clinic-search-form-wrapper">
          <form className="form-inline" id="search-form" autoComplete="off" onSubmit={
            (e) => {
              e.preventDefault();
              props.onSubmit(cityZip, mileRadius, clinicType);
            }
          }>
            <div className="form-row align-items-center">
              { process.env.REACT_APP_DEBUG === "true" &&
                <div className="col-auto">
                  <select
                    className="form-control mb-2 mr-sm-2"
                    id="typeSelect"
                    onChange={(e) => setClinicType(e.target.value)}
                    value={clinicType}
                  >
                    <option value="" disabled>Clinic Type</option>
                    <option value="cosmetic">Cosmetic</option>
                    <option value="dental">Dental</option>
                    <option value="eye">Eye</option>              
                    <option value="pharmacy">Pharmacy</option>
                  </select>
                </div>
              }

              <div className="col-auto">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">City or Zip</div>
                  </div>
                  <input
                    type="text"
                    className="form-control mr-sm-2"
                    id="cityZip"
                    onChange={(e) => setCityZip(e.target.value.toLocaleUpperCase())}
                  />
                </div>
              </div>

              <div className="col-auto">
                <label className="form-check-label" id="withinLabel">
                  Within
                </label>
              </div>

              <div className="col-auto">
                <select
                  type="text"
                  className="form-control mb-2 ml-2 mr-sm-2"
                  id="miles"
                  value={mileRadius}
                  onChange={(e) => setMileRadius(e.target.value)}
                >
                  <option value={5} defaultValue>5 Miles</option>
                  <option value={10}>10 Miles</option>
                  <option value={15}>15 Miles</option>
                  <option value={20}>20 Miles</option>
                  <option value={25}>25 Miles</option>
                </select>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success mb-2"
              id="result-btn"
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Search;
