import React from 'react';
//import MapContainer from './MapContainer';

const ClinicListItem = (props) => {
  const address = `${props.address1}, ${props.address2 !== null ? props.address2 : ""} ${props.city}, ${props.state} ${props.zip_code}`;
  return (
    <li>
      <div className="row">
        <div className="col">
          <span className="pharmacy-name">{props.name} </span>
          <span className="view-map-link"><nobr>
            <a target="new" href={`https://google.com/maps?q=${address}`}>View Map</a></nobr>
          </span><br />
          <span className="pharmacy-address">{address}</span>
        </div>
        {/*}
        <div className="col">
          <MapContainer lat={props.latitude} lng={props.longitude} name={props.name} />
        </div>
  */}
      </div>        
    </li>
  );
           
}
export default ClinicListItem;
