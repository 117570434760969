import "react-app-polyfill/ie9";
import 'core-js/features/array/includes';
import './css/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

/*
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://79bcc32830bb48a18a32c99946e50286@sentry.io/1355366"
  });
}
*/

let clinicType = '';

// Pull the get vars from the zoid component
// If it isn't in an iframe, grab the get vars and set clinicType
if (window.xprops !== undefined && window.xprops.clinicType !== undefined) {
  clinicType = window.xprops.clinicType;
} else if (document.location.toString().indexOf('?') !== -1) {
    const query = document.location.toString()
                   // get the query string
                   .replace(/^.*?\?/, '')
                   .replace(/#.*$/, '')
                   .split('&');

    for (let i = 0, l = query.length; i < l; i++) {
       let params = decodeURIComponent(query[i]).split('=');
       if (params[0] === "clinicType") {
         clinicType = params[1];
       }
    }
}

ReactDOM.render(
  <App clinicType={clinicType} />, 
  document.getElementById('root')
);
