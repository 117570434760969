import '../css/Spinner.css';

import React from "react";
import Loader from 'react-loader-spinner';


const Spinner = () => {
  return (
    <div className="spinner">
      <Loader
        type="TailSpin"
        color="#00BFFF"
        height="130"
        width="130"
      />
    </div>
  );

}

export default Spinner;
