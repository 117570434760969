import React from 'react';
import ClinicListItem from './ClinicListItem';
import "../css/Results.css";

const Results = (props) => {
  
  let rows = props.clinicReturn !== []
    ? props.clinicReturn.map(
        (clinic, index) => {
          const c = clinic.attributes;
          return (
            <ClinicListItem
              key={index}
              name={c.display_name || c.name}
              address1={c.address1}
              address2={c.address2}
              city={c.city}
              state={c.state}
              zip_code={c.zip_code}
              phone={c.phone}
              notes={c.notes}
              latitude={c.latitude}
              longitude={c.longitude}
            />
          )
        }
      )
    : ''

  return (
    <div>
      { props.clinicReturn.length > 0 &&
        <div>
          <hr />
          <h3 className="">
            Search Results For: <nobr />
            <span className="results-header">{props.cityZip}</span> <nobr />
            within <span className="results-header">{props.mileRadius} Miles</span>
          </h3>
          <ul className="search-results-list">
            {rows}
          </ul>
        </div>
      }
    </div>
  );
}

export default Results;
