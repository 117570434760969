// Styles
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "./css/App.css"

import React, { Component } from "react"
//import ErrorBoundary from './components/ErrorBoundary';
import Search from "./components/Search"
import Results from "./components/Results"
import Spinner from "./components/Spinner"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicReturn: [],
      cityZip: "",
      mileRadius: "5",
      clinicType: props.clinicType,
      errorMessage: null,
      loading: false
    }
  }

  render() {
    let results =
      this.state.loading === false ? (
        <Results
          clinicReturn={this.state.clinicReturn}
          cityZip={this.state.cityZip}
          mileRadius={this.state.mileRadius}
          loading={this.state.loading}
        />
      ) : (
        <Spinner />
      )

    return (
      <>
        {/*<ErrorBoundary>*/}
        <div className="container-fluid">
          <Search
            onSubmit={this.getData}
            errorMessage={this.state.errorMessage}
            clinicType={this.state.clinicType}
          />
          {results}
        </div>
        {/*</ErrorBoundary>*/}
      </>
    )
  }

  getData = async (cityZip, mileRadius, clinicType) => {
    this.setState({
      cityZip: cityZip,
      mileRadius: mileRadius,
      clinicType: clinicType,
      loading: true
    })
    let err = null

    if (cityZip.length === 0) {
      err = "Please enter a city or zip code."
      this.setState({ loading: false, errorMessage: err })
      return false
    }

    if (clinicType.length === 0) {
      err = "Please select a clinic type."
      this.setState({ loading: false, errorMessage: err })
      return false
    }

    await fetch(
      `${
        process.env.REACT_APP_API_URL
      }?type=${clinicType}&zip=${cityZip}&radius=${mileRadius}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer dOsHkFMHTeo99GWqY0mY"
        }
      }
    )
      .then(response => {
        if (!response.ok) {
          err = "There was a problem processig you request. Please try again"
        }
        return response.json()
      })
      .then(data => {
        if (data.data.length === 0) {
          err = "There were no results found."
        }
        this.setState({ clinicReturn: data.data })
      })
      .catch(error => {
        err = "There was a problem processig you request. Please try again"
        console.error(error)
      })

    this.setState({ errorMessage: err, loading: false })
  }
}

export default App
